<template>
  <div class="dutyInfos-managet-action">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" :content="`值班日历${tipContent}`" />
    <!-- 数据表格 -->
    <ml-tip
      content=""
      :showHeader="false"
      class="default-tip"
      :style="`margin-bottom: 0; border: 0; min-height: ${tabberHeight(175)}px`"
    >
      <div class="duty-box" :style="{ height: `${tabberHeight(185)}px` }">
        <div class="duty-box-left">
          <div style="padding: 20px 50px 0 40px">
            <!-- 日历 -->
            <div id="zxVueCalendar" style="width: 400px"></div>
          </div>
          <el-scrollbar :height="`${tabberHeight(550)}px`">
            <class-item v-model="classData" :isView="false" @on-add-class="onAddClass" />
          </el-scrollbar>
        </div>
        <div class="duty-box-right">
          <!-- 右侧标题 -->
          <ml-tip
            :showHeader="false"
            :style="{ borderBottom: 0 }"
            :bodyStyle="{ padding: '10px 20px' }"
          >
            <div class="duty-box-right-tip">
              <div class="duty-box-right-tip-title">
                编辑值班信息
                {{ year }}-{{ smallTenStr(month) }}-{{ smallTenStr(day) }}
              </div>

              <div>
                <el-button type="text" @click="onReset">取消</el-button>
                <el-button type="text" @click="onSaveToDay">保存当天</el-button>
                <el-button type="text" @click="onSaveTheMonth">保存当月</el-button>
              </div>
            </div>
          </ml-tip>
          <duty-table v-model="classData" :isView="false" />
        </div>
      </div>
    </ml-tip>
  </div>
</template>

<script>
import { nextTick, onMounted, onUnmounted, ref } from 'vue'
import { tabberHeight, smallTenStr } from '@/utils'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import ZxVueCalendar from 'zx-calendar'

import DutyTable from './components/table.vue'
import ClassItem from './components/classItem.vue'

export default {
  name: 'DtyInfosManageAction',
  components: {
    DutyTable,
    ClassItem
  },
  setup() {
    const { commit, dispatch } = useStore()
    // 获取地址栏参数
    const router = useRouter()
    const route = useRoute()
    const query = route.query

    // tip 提示 阿米巴 项目
    const tipContent = ref('')
    // 处理当前时间，年月日
    const nDate = new Date()
    const year = ref(query.year || nDate.getFullYear())
    const month = ref(query.month || nDate.getMonth() + 1)
    const day = ref(query.day || nDate.getDate())
    // 日历对象
    const zxVueCalendar = ref()
    const recordId = ref('')
    const isHhliday = ref([])
    // 值班ID
    const dutyCalendars = ref({})
    // 初始化班次选择
    // const initClass = {
    //   startDayType: 0,
    //   endDayType: 0,
    //   startTime: '09:00',
    //   endTime: '12:00',
    //   dutyTimeItemUsers: [],
    //   dutyTimeHours: []
    // }

    // 班次信息
    const classData = ref([])

    // 添加班次信息
    const onAddClass = () => {
      classData.value = [
        ...classData.value,
        {
          startDayType: 0,
          endDayType: 0,
          startTime: '09:00',
          endTime: '17:00',
          dutyTimeItemUsers: [],
          dutyTimeHours: []
        }
      ]
    }
    // 获取详情
    const getDutyInfosDetails = async () => {
      const { data } = await dispatch('fetchGetDutyInfo', query.dutyId)
      // 收集阿米巴与项目
      const { companyName, projectName } = data
      if (companyName && projectName) {
        tipContent.value = ` - ${companyName} - ${projectName}`
      }
    }
    // 获取值班日历集合
    const getDutyCalendars = async () => {
      const { data = [] } = await dispatch('fetchGetDutyCalendars', {
        dutyId: query.dutyId
        // year: year.value,
        // month: month.value
      })
      isHhliday.value = []
      // 收集值班日与值班对应的ID
      data.map(item => {
        isHhliday.value.push(item.everyDate)
        dutyCalendars.value[item.everyDate] = item.recordId
        const m = smallTenStr(month.value)
        const d = smallTenStr(day.value)
        if (item.everyDate === `${year.value}-${m}-${d}`) {
          recordId.value = item.recordId
          getDutyTimeItems(item.recordId)
        }
      })
      // 创建日历
      if (zxVueCalendar.value) {
        const m = smallTenStr(month.value)
        const d = smallTenStr(day.value)
        zxVueCalendar.value.setDate(new Date(`${year.value}-${m}-${d}`))
      } else {
        const ZxVueCalendarOption = {
          el: '#zxVueCalendar',
          showHoliday: true,
          // 格式化日历项
          itemFormatter: e => {
            if (e.fullText) {
              // 查看日历项是否是激活日期
              const fullText = e.fullText.replaceAll('/', '-')
              // 如果日期正常并存在值班就显示点
              e.holiday = isHhliday.value.includes(fullText)
            }
            return e
          }
        }
        if (query.year && query.month && query.day) {
          ZxVueCalendarOption.defaultDate = `${query.year}-${query.month}-${query.day}`
        }
        zxVueCalendar.value = new ZxVueCalendar(ZxVueCalendarOption)
      }
    }
    // 查询班次信息集合
    const getDutyTimeItems = async calRecordId => {
      const { data = [] } = await dispatch('fetchGetDutyTimeItems', { calRecordId })
      if (data.length > 0) {
        classData.value = data
      }
    }

    // 加载值班日历后创建日历并监听日历事件
    nextTick(async () => {
      await getDutyCalendars()
      if (zxVueCalendar.value) {
        zxVueCalendar.value.on('change', e => {
          // const fullText = e[0].fullText.replaceAll('/', '-')
          const [y, M, d] = e[0].fullText.split('/')
          year.value = y
          month.value = M
          day.value = d
          recordId.value = dutyCalendars.value[`${y}-${M}-${d}`]
          if (recordId.value) {
            getDutyTimeItems(recordId.value)
          } else {
            classData.value = [
              {
                startDayType: 0,
                endDayType: 0,
                startTime: '09:00',
                endTime: '17:00',
                dutyTimeItemUsers: [],
                dutyTimeHours: []
              }
            ]
          }
        })
      }
    })

    // 编辑事件
    const onReset = () => {
      router.push({
        path: '/dutyInfosManagetDetails',
        query: { dutyId: query.dutyId, year: query.year, month: query.month, day: query.day }
      })
      // router.go(-1)
      // if (recordId.value) {
      //   getDutyTimeItems(recordId.value)
      // } else {
      //   classData.value = [
      //     {
      //       startDayType: 0,
      //       endDayType: 0,
      //       startTime: '09:00',
      //       endTime: '12:00',
      //       dutyTimeItemUsers: [],
      //       dutyTimeHours: []
      //     }
      //   ]
      // }
    }

    // 保存接口
    const dutyCalendarSave = (type = 'day') => {
      let isUsers = true
      const nClassData = classData.value.map((item, index) => {
        if (!item.dutyTimeItemUsers || item.dutyTimeItemUsers.length <= 0) {
          isUsers = false
        }
        if (item.dutyCalendarSummarys) {
          delete item.dutyCalendarSummarys
        }
        item.className = `班次${index + 1}`
        return item
      })
      const params = {
        dutyId: query.dutyId,
        saveType: type,
        everyDate: `${year.value}-${month.value}-${day.value}`,
        dutyTimeItems: nClassData
      }
      if (recordId.value) {
        params.recordId = recordId.value
      }
      if (isUsers) {
        dispatch('fetchDutyCalendarSave', params).then(data => {
          if (data && data.code === 200) {
            commit('setError', {
              status: true,
              title: data.message,
              message: '正在获取新的数据...',
              type: 'success'
            })
            recordId.value = data.data
            if (!isHhliday.value.includes(data.data)) {
              isHhliday.value.push(data.data)
            }
            getDutyCalendars()
          }
        })
      } else {
        commit('setError', {
          status: true,
          message: '值班人员不能为空',
          type: 'warning'
        })
      }
    }

    // 保存当天
    const onSaveToDay = () => {
      dutyCalendarSave('day')
    }
    // 保存当天
    const onSaveTheMonth = () => {
      dutyCalendarSave('month')
    }

    onMounted(() => {
      getDutyInfosDetails()
      if (classData.value.length <= 0) {
        classData.value = [
          {
            startDayType: 0,
            endDayType: 0,
            startTime: '09:00',
            endTime: '17:00',
            dutyTimeItemUsers: [],
            dutyTimeHours: []
          }
        ]
      }
    })

    onUnmounted(() => {
      zxVueCalendar.value.destroy()
    })
    return {
      tabberHeight,
      tipContent,
      year,
      month,
      day,
      onReset,
      onSaveToDay,
      onSaveTheMonth,
      classData,
      onAddClass,
      smallTenStr
    }
  }
}
</script>

<style lang="scss">
.dutyInfos-managet-action {
  @extend %params-global;
}
.duty-box {
  display: flex;
  height: 100%;
  &-left {
    width: 480px;
    border-right: 1px solid #d8d8d8;
  }
  &-right {
    flex: 1;
    &-tip {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-title {
        font-weight: bold;
      }
    }
  }
}

// 日历样式
.zx-calendar .zx-calendar-header-wrapper .__l button:before,
.zx-calendar .zx-calendar-header-wrapper .__l button:after,
.zx-calendar .zx-calendar-header-wrapper .__r button:before,
.zx-calendar .zx-calendar-header-wrapper .__r button:after {
  border-top: 1px solid #409eff;
  border-right: 1px solid #409eff;
}
.zx-calendar .zx-calendar-body-wrapper .__holiday {
  background: #409eff;
}
.zx-calendar .zx-calendar-body-wrapper .__item.is-current .__inner .__text {
  background: #fff;
  color: #409eff;
}
.zx-calendar .zx-calendar-body-wrapper .__item.is-selected .__inner .__text,
.zx-calendar .zx-calendar-body-wrapper .__item.is-range-temp .__inner .__text,
.zx-calendar .zx-calendar-body-wrapper .__item.is-range-first-last .__inner .__text {
  background: #409eff;
  color: #fff;
}
</style>
